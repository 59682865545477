import React from "react";
import berainLogo from "../../assets/main-logo.png";
import "../../style.css";
const ComponentToPrint = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref}>
       <div className="header">
        <img src={berainLogo} alt="Logo" className="logo" />
        <div className="header-text">
          <h1 className="title">title</h1>
          <p className="emp-id">Employee ID: '215</p>
        </div>
      </div>

      {/* Date */}
      <div className="date">
        <p className="date-text">Date:12-12-1201</p>
      </div>

      {/* Invoice Table */}
      <table className="invoice-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {/* {items.map((item, index) => (
            <tr key={index}>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
              <td>{item.price.toFixed(2)}</td>
              <td>{item.total.toFixed(2)}</td>
            </tr>
          ))} */}
        </tbody>
      </table>

      {/* Total Amount */}
      <div className="total-amount">
        <p>Total Amount: 1000</p>
      </div>

      {/* Signatures */}
      <div className="signatures">
        <div className="signature">
          <p>Accountant's Signature</p>
        </div>
        <div className="signature">
          <p>Salesman's Signature</p>
        </div>
      </div>

      <style>{`
        .invoice-container {
          max-width: 800px;
          margin: 0 auto;
          padding: 24px;
          border: 1px solid #ddd;
          border-radius: 8px;
          font-family: Arial, sans-serif;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
        }

        .logo {
          height: 50px;
        }

        .header-text {
          text-align: center;
        }

        .title {
          font-size: 24px;
          font-weight: bold;
        }

        .emp-id {
          font-size: 12px;
        }

        .date {
          text-align: right;
          margin-bottom: 24px;
        }

        .invoice-table {
          width: 100%;
          margin-bottom: 24px;
          border-collapse: collapse;
        }

        .invoice-table th,
        .invoice-table td {
          padding: 8px;
          border: 1px solid #ddd;
          text-align: left;
        }

        .invoice-table th {
          background-color: #f7f7f7;
        }

        .total-amount {
          display: flex;
          justify-content: flex-end;
          font-weight: bold;
          margin-bottom: 24px;
          padding-top:24px
        }

        .signatures {
          display: flex;
          justify-content: space-between;
        }

        .signature {
          text-align: center;
          border-top: 1px solid #ddd;
          padding-top: 16px;
          width: 45%;
        }

        .signature p {
          margin-top: 8px;
          font-size: 14px;
        }
      `}</style>
      </div>
  );
});

export default ComponentToPrint;
