import { getHeader, getUserInfo } from "../utility/index";
import axios from "./config";

interface DecodedToken {
  emp_id: string;
}


interface ApiResponse {
    data: any;
  }

const depositSummary = async (params:any,data: any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
    const response = await axios.post<ApiResponse>(
      `/reports/${user_info.emp_id}/deposite-summary-report`,
      data,
      { headers: getHeader() ,
        params: params,
      }
    );
    return response;
  };

export default depositSummary