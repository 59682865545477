import React, { useState, useEffect } from "react";
import DashboardPage from "../../layout/index";
import SalesmanList from "./Salesman-list";
import SalesmanOption from "./SalesmanOption";
import GoogleMap from "../../components/GoogleMap";
import getEmployeeList, {
  getEmployeeSchedule,
  getMarkerInfo,
  getStream,
  getStreamHistory,
  getLastLocation,
} from "../../api/mapView";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getUserInfo,
} from "../../utility";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

interface Employee {
  emp_id: string;
  name: string;
  age: number;
  department: string;
}

export default function Salesman() {
  const [selctedEmp, setSelectedEmp] = useState<any>("All employees");
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [employeeSchedule, setEmployeeSchedule] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<any | null>();
  const [endDate, setEndDate] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [locationData, setLocationData] = useState<any | null>([]);
  const [markarData, setMarkarData] = useState<any | null>(null);
  const [selectedEmpPopup, setSelectedEmpPopup] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("planned");
  const [actualData, setActualdata] = useState<any>("");
  const [routeForSelectedCustomer, setRouteForSelectedCustomer] =
    useState<any>("");
  const [vehicleLocation, setVehicleLoaction] = useState<any>();

  const { t } = useTranslation();

  const handleRouteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedRoute === e.target.value) {
      setSelectedRoute("");
    } else {
      setSelectedRoute(e.target.value);
    }
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    const today = new Date();
    setDateRange(today);
    getEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStreamData = async (emp_data: any[]) => {
    const empIds = emp_data?.map((emp: any) => emp.emp_id);
    const user_info = getUserInfo();
    const data = {
      emp_id: user_info.emp_id,
      requested_emp_ids: empIds,
    };
    await getStream(data)
      .then((res) => {
        getEventSource(res.data.stream_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateVehicleLastLocation = (data: any) => {
    if (formatDate(new Date()) === selectedDate) {
      setVehicleLoaction({
        lat: Number(data.latitude),
        lng: Number(data.longitude),
      });
    }
  };

  const handleStreamResponse = (data: any) => {
    if (data?.init) {
      return;
    }
    const existingMarkerIndex = locationData.findIndex(
      (marker: any) => marker.emp_id === data.emp_id
    );

    if (selctedEmp?.emp_id === data.emp_id) {
      updateVehicleLastLocation(data);
    }

    if (existingMarkerIndex !== -1) {
      // Update existing marker
      const updatedMarkers = [...locationData];
      updatedMarkers[existingMarkerIndex] = {
        ...updatedMarkers[existingMarkerIndex],
        location: {
          lat: Number(data.latitude), // Update latitude
          lng: Number(data.longitude), // Update longitude
        },
        status:
          data.ischecked_in === "True"
            ? "at_location"
            : data.is_idle === "True"
            ? "idle"
            : "enroute",
      };
      setLocationData(updatedMarkers);
    } else {
      // Add new marker
      if (data.emp_id) {
        setLocationData([
          ...locationData,
          {
            location: {
              lat: Number(data.latitude),
              lng: Number(data.longitude),
            },
            emp_id: data.emp_id,
            status: data.ischecked_in === "True"
                ? "at_location"
                : data.is_idle === "True"
                ? "idle"
                : "enroute",
          },
        ]);
      }
    }
  };

  let eventSource: EventSource | null = null;

  const getEventSource = (streamId: number) => {
    const token = Cookies.get("token");

    const env = process.env.REACT_APP_ENV;
    let baseUrlGps = null;

    if (env === "development") {
      baseUrlGps = process.env.REACT_APP_DEV_GPS_API_URL;
    } else if (env === "staging") {
      baseUrlGps = process.env.REACT_APP_STAGING_GPS_API_URL;
    } else if (env === "production") {
      baseUrlGps = process.env.REACT_APP_PROD_GPS_API_URL;
    } else {
      console.error("No valid environment specified");
    }
    eventSource?.close();
    eventSource = new EventSource(
      `${baseUrlGps}/gps/stream/${streamId}?token=${token}`
    );
    eventSource.onmessage = (event: any) => {
      handleStreamResponse(JSON.parse(event.data));
    };

    eventSource.onerror = (err) => {
      console.error("EventSource error:", err);
      eventSource?.close();
    };
  };

  const stopEventSource = () => {
    if (eventSource) {
      eventSource.close();
      eventSource = null; // Reset the reference
    }
  };

  React.useEffect(() => {
    return () => {
      stopEventSource();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStreamDataHistory = async (data: any) => {
    await getStreamHistory(data)
      .then((res) => {
        formattedHistoryData(res.data?.events);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formattedHistoryData = (data: any) => {
    const ks = data.map((item: any) => ({
      lat: parseFloat(item.location.lat),
      lng: parseFloat(item.location.lng),
    }));
    setActualdata(ks);
  };

  const hanldeLocationData = (data: any) => {
    const convertedData = data.map((item: any) => ({
      ...item,
      location: {
        lat: parseFloat(item.location.lat),
        lng: parseFloat(item.location.lng),
      },
      status: "enroute",
    }));
    return convertedData;
  };
  const getLastLocationData = async (data: any) => {
    const empIds = data.map((employee: any) => employee.emp_id);
    await getLastLocation(empIds)
      .then((res) => {
        setLocationData(hanldeLocationData(res?.data?.locations));
      })
      .catch((error) => {
        console.log(error);
        setLocationData([]);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDateRange = (date: any) => {
    const formattedToday = formatDate(date);
    setSelectedDate(formattedToday);

    setStartDate(formatCurrentStartDate(date));
    setEndDate(formatCurrentEndDate(date));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);

    if (!isNaN(selectedDate.getTime())) {
      setStartDate(formatCurrentStartDate(selectedDate));
      setEndDate(formatCurrentEndDate(selectedDate));

      const formattedToday = formatDate(selectedDate);
      setSelectedDate(formattedToday);

      getEmpSchedule(selctedEmp, {
        start_date: formatCurrentStartDate(selectedDate),
        end_date: formatCurrentEndDate(selectedDate),
      });

      const data = {
        requested_emp_ids: [selctedEmp.emp_id],
        start_time: formatCurrentStartDate(new Date(selectedDate)),
        end_time: formatCurrentEndDate(new Date(selectedDate)),
      };
      getStreamDataHistory(data);
    }
  };

  const handleEmpSected = (person: any) => {
    setRouteForSelectedCustomer("");
    if (selctedEmp.emp_id === person.emp_id) {
      setSelectedEmp("All employees");
      setIsDropdownOpen(false);
      return;
    } else {
      setSelectedEmp(person);
      setIsDropdownOpen(false);
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      getEmpSchedule(person, params);
      const data = {
        requested_emp_ids: [person.emp_id],
        start_time: formatCurrentStartDate(new Date(selectedDate)),
        end_time: formatCurrentEndDate(new Date(selectedDate)),
      };
      getStreamDataHistory(data);
    }
  };

  const getEmpSchedule = async (person: any, params: any) => {
    setLoader(true);
    const sub_id = person.emp_id;
    await getEmployeeSchedule(sub_id, params)
      .then((res) => {
        setEmployeeSchedule(res.data);
        setSelectedRoute("planned");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const getMarkerInfoData = async (emp_id: any) => {
    const params = {
      start_date: formatCurrentStartDate(new Date(selectedDate)),
      end_date: formatCurrentEndDate(new Date(selectedDate)),
    };
    await getMarkerInfo(emp_id, params)
      .then((res) => {
        setMarkarData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmployee = async () => {
    await getEmployeeList()
      .then((res) => {
        setEmployeeData(res.data);
        getLastLocationData(res.data);
        getStreamData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [activePopupIndex, setActivePopupIndex] = useState<number | null>(null);

  const handleSelectedEmpIcon = () => {
    setSelectedEmpPopup(!selectedEmpPopup);
    if (!selectedEmpPopup) {
      getMarkerInfoData(selctedEmp.emp_id);
    }
  };

  const infoIconClick = (index: number, id: any) => {
    setActivePopupIndex(activePopupIndex === index ? null : index);
    if (activePopupIndex !== index) {
      getMarkerInfoData(id);
    }
  };

  const handleMarkerClick = (marker: any) => {
    setMarkarData(null);
    setSelectedMarker(marker);
    getMarkerInfoData(marker?.emp_id);
  };

  return (
    <DashboardPage>
      <div className="flex  flex-col md:flex-row h-[calc(100vh-54px)] ">
        <div className="w-full md:w-[25%]  md:h-full ">
          <SalesmanList
            selctedEmp={selctedEmp}
            handleEmpSected={handleEmpSected}
            employeeData={employeeData}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            selectedEmpPopup={selectedEmpPopup}
            handleSelectedEmpIcon={handleSelectedEmpIcon}
            infoIconClick={infoIconClick}
            activePopupIndex={activePopupIndex}
            markarData={markarData}
          />
        </div>
        {selctedEmp !== "All employees" ? (
          <>
            <div className="border-none md:border-solid  md:border-l-2 md:border-r-2 h-full w-full md:w-[50%] p-4 min-h-[520px]">
              <GoogleMap
                showDirections={true}
                plannedData={
                  employeeSchedule?.planned_route_details?.directions?.routes
                }
                actualData={actualData}
                selectedRoute={selectedRoute}
                handleRouteChange={handleRouteChange}
                markarData={markarData}
                employeeSchedule={employeeSchedule}
                routeForSelectedCustomer={routeForSelectedCustomer}
                vehicleLocation={vehicleLocation}
              />
            </div>
            <div className="w-full md:w-[30%] h-full ">
              <SalesmanOption
                employeeSchedule={employeeSchedule}
                handleDateChange={handleDateChange}
                selectedDate={selectedDate}
                loader={loader}
                setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                routeForSelectedCustomer={routeForSelectedCustomer}
              />
            </div>
          </>
        ) : (
          <>
            <div className="border-l-2 min-h-[420px] w-full md:w-[78%] md:h-full p-4 h-[320px]">
              <GoogleMap
                showMarkers={true}
                markersData={locationData}
                setSelectedMarker={setSelectedMarker}
                selectedMarker={selectedMarker}
                handleMarkerClick={handleMarkerClick}
                employeeSchedule={employeeSchedule}
                routeForSelectedCustomer={routeForSelectedCustomer}
                markarData={markarData}
                handleCloseInfoWindow={() => setSelectedMarker(null)}
              />
              {selectedMarker && (
                <div className="md:hidden w-full bg-culightgrey h-auto p-6 mt-4 border border-gray-500 rounded">
                  <div className="text-16 font-bold">
                    {selectedMarker?.label}
                  </div>
                  <div className="pt-2">
                    <p>
                      {t("visit.total_travel")}:
                      {markarData?.total_traveling_till_now} {t("visit.km")}
                    </p>
                    <p>
                      {t("visit.total_visit")} : {markarData?.total_visits}
                    </p>
                    <p>
                      {t("visit.successful_visit")} :{" "}
                      {markarData?.successful_visits}
                    </p>
                    <p>
                      {t("visit.failed_visit")}: {markarData?.failed_visits}{" "}
                    </p>
                    <p>
                      {t("visit.collection_visit")}:{" "}
                      {markarData?.productive_visits}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </DashboardPage>
  );
}
