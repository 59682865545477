import Cookies from "js-cookie";
import { getUserInfo } from "../utility";
import React, { createContext, useContext, useEffect, useState } from "react";
import BellSound from "../assets/audio/bell.mp3";

let eventSource: EventSource | null = null;

interface NotificationContextType {
  notifications: Notification[];
  approveNotification: (id: number) => void;
  hasNewNotifications: boolean;
  setHasNewNotifications: any;
  notif: any;
  setNotif: any;
  setCount: any;
  count: any;
  callingSseEvent?: any;
  DisconnectingSseEvent?: any;
}
const NotificationContext = createContext<NotificationContextType | null>(null);

interface NotificationProviderProps {
  children: any;
}
export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useState<string | any>(Cookies.get("token") || "");
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [notif, setNotif] = useState(false);
  const [count, setCount] = useState(0);

  const playNotificationSound = () => {
    setNotif(true);
    const audio = new Audio(BellSound);
    audio.play().catch((error) => console.error("Error playing sound:", error));
  };

  const connectWithsse = (token: string) => {
    if (!token) {
      return;
    }
    let baseURL: any = "";
    const env = process.env.REACT_APP_ENV;
    const user_info = getUserInfo();
    if (env === "development") {
      baseURL = process.env.REACT_APP_DEV_API_URL;
    } else if (env === "staging") {
      baseURL = process.env.REACT_APP_STAGING_API_URL;
    } else if (env === "production") {
      baseURL = process.env.REACT_APP_PROD_API_URL;
    }
    eventSource?.close();
    eventSource = new EventSource(
      `${baseURL}/supervisors/notifications/${user_info.emp_id}?token=${token}`
    );
    eventSource.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      playNotificationSound();
      setCount((prevCount) => prevCount + 1);

      setHasNewNotifications(true);
      setNotifications((prev) => [...prev, newNotification]);
    };
  };
  useEffect(() => {
    connectWithsse(token);
    return () => {
      eventSource?.close();
      eventSource = null;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!token) {
      eventSource?.close();
    }
  }, [token]);

  const callingSseEvent = (token: string) => {
    setTimeout(() => {
      connectWithsse(token);
    }, 2000);
  };
  const DisconnectingSseEvent = () => {
    eventSource?.close();
  };

  const approveNotification = (id: number) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification?.id !== id)
    );
  };
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        approveNotification,
        hasNewNotifications,
        setHasNewNotifications,
        notif,
        setNotif,
        count,
        setCount,
        callingSseEvent,
        DisconnectingSseEvent,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within NotificationProvider"
    );
  }
  return context;
};
