import React, { useEffect } from "react"; // Import React (optional in new versions but good practice)
import "./App.css";
import "./style.css";
import { BrowserRouter as ReactRoutes } from "react-router-dom";
import AllRoutes from "./routes/routes";
import { refreshToken } from "./api/login";
import Cookies from "js-cookie";
import { NotificationProvider } from "./notification";
import "react-toastify/dist/ReactToastify.css";
import { useAxiosInstance } from "./api/config";
import Popup from "./components/AuthPopup";
import AutoLogout from "./layout/Autologout";


const App: React.FC = () => {
  
  const { showPopup, popupMessage, setShowPopup, title } = useAxiosInstance();

  const refreshAcessToken = async () => {
    await refreshToken()
      .then((res) => {
        Cookies.set("token", res?.data?.access_token);
        Cookies.set("refresh_token", res?.data?.refresh_token);
      })
      .catch((error) => {
        console.log(error);
        Cookies.remove("token");
        Cookies.remove("refresh_token");
        window.location.href = "/";
      });
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      refreshAcessToken();
    }
    const intervalId = setInterval(refreshAcessToken, 400000);
    return () => clearInterval(intervalId);
  }, []);



  return (
    <NotificationProvider>
      <Popup
        isOpen={showPopup}
        title={title}
        setShowPopup={setShowPopup}
        >
        <p>{popupMessage}</p>
      </Popup>
      <ReactRoutes>
        <AutoLogout>
        <AllRoutes />
      </AutoLogout>
      </ReactRoutes>
    </NotificationProvider>
  );
};

export default App;
