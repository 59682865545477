import React, { useEffect, useState, useRef } from "react";
import DashboardPage from "../../layout";
import DepositSummary from "../../api/reports";
import PaginationComponent from "../../components/Pagination";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  formatNumber,
  getUserInfo,
} from "../../utility";
import Export from "../../assets/icons/Export.svg";
import Filter from "../../assets/icons/Filter.svg";
import Loader from "../../components/Loader";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import Close from "../../assets/close-circle.svg";
import Dropdown from "../../components/Dropdown";
import Search from "../../assets/Search.svg";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";

const CustomerTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [customers, setCustomersData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [sortType, setSortType] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [emp, setEmployee] = useState<any>("");
  const [paymentType, setPaymentType] = useState<any>("");

  const handleFilterClick = () => {
    setOpen(true);
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleClearFilters = () => {
    setEmployee(null);
    setPaymentType(null);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setOpen(false);
    setSelectTimeFrame("Today");
    handleClearApply("");
  };

  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: currentPage,
    };

    getCustomerData(data, params);
    handleClose();
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e);
     const user_info = getUserInfo();
    const data = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      emp_id:user_info.emp_id,
      search: e,
    };
    const params = {
      page: currentPage,
    };
    getCustomerData(data, params);
  };

  const handleSortFillter = (data: string) => {
    setSortType(data);
    setIsDropdownOpen(false);
    handleApply(data);
  };

  const handleClose = () => setOpen(false);

  const getCustomerData = async (data: any, params: any) => {
    setCustomersData([]);
    setLoading(true);
    await DepositSummary(params, data)
      .then((res) => {
        setLoading(false);
        setCustomersData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer data:", error);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const user_info = getUserInfo();
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      emp_id:user_info.emp_id,
    };

    if (emp) {
      data["customer_ids"] = [emp];
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }

    const params = {
      page: currentPage,
      size: customersPerPage,
    };
    getCustomerData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  const handleApply = (sort: string) => {
    const user_info = getUserInfo();
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      emp_id:user_info.emp_id,
    };

    if (emp) {
      data["customer_ids"] = [emp];
    }
    if (paymentType) {
      data["payment_type"] = [paymentType];
    }

    const params = {
      page: currentPage,
    };
    getCustomerData(data, params);
    handleClose();
  };

  const printData = async () => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
    };

    if (emp) {
      data["customer_ids"] = [emp];
    }
    if (paymentType) {
      data["payment_type"] = [paymentType];
    }

    const printContent = printRef.current;
    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow && printContent) {
      newWindow.document.write(printContent.outerHTML);
      newWindow.document.close();
      newWindow.focus();
      newWindow.print();
      newWindow.close();
    }
  };

  return (
    <DashboardPage>
      <div className="bg-gray-50 pl-[10px] lg:pl-[15px] md:pl-[15px] sm:pl-0 pr-4">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={customers?.total}
              itemsPerPage={customers?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={Search} alt={t("masterList.searchPlaceholder")} />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={t("masterList.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>
            <div className="hidden sm:flex space-x-4 items-center relative">
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "ASC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("masterList.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${
                      sortType === "DESC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("masterList.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center px-4 py-2 text-13  rounded transition-all"
              >
                <img
                  src={Filter}
                  alt={t("masterList.filter")}
                  className="w-4 h-4 mr-2"
                />
                {t("masterList.filter")}
              </button>
              <div></div>
              <button
                onClick={printData}
                className="flex items-center px-4 py-2 text-13 font-medium border rounded transition-all"
              >
                Print
                <img
                  src={Export}
                  alt={t("masterList.export")}
                  className="w-4 h-4 ml-2"
                />
              </button>
              <div className="hidden">
                <ComponentToPrint ref={printRef} />
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="relative w-full sm:w-auto">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Add your search icon here */}
              <img src={Search} alt={t("masterList.searchPlaceholder")} />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t("masterList.searchPlaceholder")}
              className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={printData}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img
                src={Export}
                alt={t("masterList.export")}
                className="w-4 h-4"
              />
            </button>
            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg w-full max-w-md h-full">
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("masterList.filters.title")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt={t("masterList.filters.title")}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6">
                <div className="flex flex-col gap-[30px]">
                  {/* Employee ID */}
                  <div>
                    <Dropdown
                      label="Employee ID"
                      options={
                        customers?.filters?.customers?.map((emp: any) => ({
                          value: emp.customer_id,
                          label: emp.customer_id,
                        })) || []
                      }
                      selectedValue={emp}
                      onChange={setEmployee}
                      placeholder="Employee name"
                    />
                  </div>
                  <div>
                    <Dropdown
                      label="Payment Type"
                      options={
                        customers?.filters?.customers?.map((emp: any) => ({
                          value: emp.customer_id,
                          label: emp.customer_id,
                        })) || []
                      }
                      selectedValue={emp}
                      onChange={setEmployee}
                      placeholder="Payment Type"
                    />
                  </div>
                  <div className="date-range-picker flex gap-4">
                    <div className="flex gap-4 w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.startDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.endDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <Button
                    onClick={handleClearFilters}
                    label={t("masterList.filters.clearFilters")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("masterList.filters.applyFilters")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          // id="printable-area"
          className="h-[calc(100vh-200px)] md:h-[calc(100vh-120px)] p-2 pt-0 overflow-auto  page-container scrollbar scrollbar-thin "
          style={{
            overflow: customers?.items?.length === 0 ? "hidden" : "auto",
          }}
        >
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  Customer ID
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  Sap External ID
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  Date
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  Payment Type
                </th>
              </tr>
            </thead>
            <tbody>
              {customers?.items?.length > 0 &&
                customers.items.map((customer: any) => (
                  <tr key={customer.emp_id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.territory_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.route_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.outlet_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.emp_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {i18n.language === "ar"
                        ? customer.customer_name_arb
                        : customer.customer_name_eng}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {customers?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("masterList.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={customers?.total}
            itemsPerPage={customers?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default CustomerTable;
